import {
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
} from "antd";
import carApi from "api/carApi";
import { addToken } from "components/store/tokenSlice";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function EditProductStatus({ open, data, onClose, onSubmit }) {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [result, setResult] = useState(null);
  const [users, setUsers] = useState([]);
  // const [defaultUser, setDefaultUser] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [soldDate, setSoldDate] = useState("");
  const [radioSelected, setRadioSelected] = useState(1);
  const [statustTypeId, setStatustTypeId] = useState(null);

  useEffect(() => {
    console.log("Data", data);
    form.resetFields();
    setResult(data);
    fetchAllUser();
    setRadioSelected(data.status_type_id);
    if (data.status_type_id === 1) {
      // setRadioSelected(data.status_type_id);
      setDisabled(true);
    } else {
      // setRadioSelected(3);
      setDisabled(false);
    }
  }, [open]);

  const fetchAllUser = async () => {
    await carApi
      .get("/salecar/admin/users/role/3", {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setUsers(data);
      })
      .catch((error) => {
        // setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClose = () => {
    setUsers([]);
    setResult(null);
    // setDefaultUser({});
    setDisabled(false);
    form.resetFields();
    onClose(false);
  };

  const handleChange = (e) => {
    form.resetFields();
    console.log("handleChange", e.target.value);
    const value = e.target.value;
    setRadioSelected(value);
    if (value === 3 || value === 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setResult({ ...result, status_type_id: value });
  };

  useEffect(() => {
    console.log("radioSelected", radioSelected);
    // setStatustTypeId(radioSelected);
  }, [radioSelected]);

  const onFinish = (values) => {
    let dataSubmit = {};
    // console.log("Submit", radioSelected);

    if (radioSelected === 3) {
      dataSubmit = {
        ...values,
        id: data.id,
        status_type_id: radioSelected,
        reserve_price: data.reserve_price,
        sold_out_date: soldDate,
        buyer_name: values.process_by_user_id,
      };
    } else {
      // console.log("radioSelected", radioSelected);
      dataSubmit = {
        id: data.id,
        status_type_id: radioSelected,
        reserve_price: values.reserve_price || null,
        sold_out_price: values.sold_out_price || null,
        sold_out_date: null,
        buyer_name: values.process_by_user_id || null,
      };
    }

    if (dataSubmit.process_by_user_id === null) {
      dataSubmit.status_type_id = 1;
    }

    onSubmit(dataSubmit);
    form.resetFields();
    handleClose();
  };

  // const checkCurrentUser = () => {
  //   const current = users.find((user) => user.id === data.user_id);
  //   setDefaultUser(current);
  //   // form.setFieldsValue({ process_by_user_id: current?.car_tent_name });
  // };

  const handelDateChange = (date, dateString) => {
    // console.log("handelDateChange", date, dateString);
    setSoldDate(dateString);
  };

  useEffect(() => {
    // checkCurrentUser();
  }, [users]);

  if (result && open && users) {
    return (
      <Modal
        title={
          "สถานะสินค้า : " +
          result.brand +
          "  " +
          result.model +
          "  " +
          result.year
        }
        open={open}
        centered
        onOk={form.submit}
        onCancel={handleClose}
      >
        <Divider />
        <Radio.Group
          direction="vertical"
          style={{ width: 500 }}
          defaultValue={
            data.status_type_id === 3 || data.status_type_id === 4
              ? 3
              : data.status_type_id
          }
          onChange={handleChange}
        >
          <Radio style={{ width: "100%" }} value={1}>
            คงอยู่
          </Radio>
          <Radio style={{ width: "100%" }} value={2}>
            รอดำเนินการ (อยู่ระหว่างซื้อขาย มัดจำ)
          </Radio>
          <Radio style={{ width: "100%" }} value={3}>
            ขายแล้ว (แบบประมูล)
          </Radio>
          {/* <Radio style={{ width: "100%" }} value={4}>
            ขายแล้ว (แบบขายปลีก)
          </Radio> */}

          <Divider />
          {radioSelected !== 1 && (
            <Form
              disabled={disabled}
              form={form}
              onFinish={onFinish}
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 18,
              }}
              autoComplete="off"
            >
              <Form.Item
                label={radioSelected === 2 ? "วันที่มัดจำ" : "วันที่ขาย"}
                name="sold_out_date"
                rules={[
                  {
                    required: true,
                    message: "เลือกวันที่ทำการ",
                  },
                ]}
              >
                <DatePicker onChange={handelDateChange} />
              </Form.Item>
              <Form.Item
                label="ผู้ซื้อ"
                name="process_by_user_id"
                // initialValue={data?.buyer_name}
                rules={[
                  {
                    required: true,
                    message: "เลือกชื่อ ผู้ซื้อ",
                  },
                ]}
              >
                <Select>
                  {users.map((user) => {
                    return (
                      <Select.Option key={user.id} value={user.id}>
                        {user.car_tent_name
                          ? user.car_tent_name
                          : user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.nickname +
                            ")"}{" "}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {radioSelected === 2 && (
                <Form.Item
                  label="เงินมัดจำ"
                  name="reserve_price"
                  initialValue={data?.reserve_price}
                  rules={[
                    {
                      required: true,
                      message: "ระบุจำนวนเงินมัดจำ",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Form.Item>
              )}

              <Form.Item
                label="ราคาขาย"
                name="sold_out_price"
                initialValue={data?.sold_out_price}
                rules={[
                  {
                    required: true,
                    message: "ระบุราคาขาย",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Form.Item>
            </Form>
          )}
        </Radio.Group>
      </Modal>
    );
  }
}
