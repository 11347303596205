import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import StockMain from "./Main";

export default function Routers() {
  const user = useSelector((state) => state.token);
  // console.log("ROUTE", user);
  return (
    <Routes>
      {user?.role === "admin" || user?.role === "sub admin" ? (
        <>
          {/* <Route path="/admin/add" element={<AddProducts />} /> */}
          <Route path="/admin" element={<StockMain />} />
          {/* <Route
            path="/admin/actions-completed-list"
            element={<AuctionCompletedList />}
          /> */}
        </>
      ) : null}
    </Routes>
  );
}
