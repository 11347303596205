import React from "react";
import Layout from "./components/ui/Layout";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// import { HashRouter as Router } from "react-router-dom";

function App() {
  const user = useSelector((state) => state.token);
  // console.log(user);

  return (
    <div>
      <Router>
        <Layout />
      </Router>
    </div>
  );
}

export default App;
