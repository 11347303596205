import React, { useEffect, useState } from "react";
// import { styled } from "@mui/material/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Link,
  Box,
} from "@mui/material";
import { Divider, Statistic, Watermark } from "antd";
import { Link as RouterLink } from "react-router-dom";

import { currencyFormat } from "components/utils/Utils";
import moment from "moment";
import { useSelector } from "react-redux";
import WaterMark from "../../../assets/images/OneLogoWatermark.png";

const { Countdown } = Statistic;

export default function ProductItem({ data }) {
  const user = useSelector((state) => state.token);
  //   const navigate = useNavigate();

  //   const navigateToDetails = () => {
  //     navigate("/products/1");
  //   };
  const [dueDateTime, setDueDateTime] = useState("");
  const [checKAuctionStatus, setAuctionStatus] = useState(false);
  const [colorCooldown, setColorCooldown] = useState("green");

  useEffect(() => {
    const currentDateTime = new Date();
    const deadLine = Date.parse(data.auction_due_datetime);
    const miliseconds = deadLine - currentDateTime;
    // console.log(miliseconds);
    if (data.status_type_id === 1) {
      if (deadLine > currentDateTime) {
        setAuctionStatus(1);
        if (miliseconds <= 12000000) {
          setColorCooldown("red");
        } else setColorCooldown("green");
      } else if (isNaN(deadLine) || deadLine === null) {
        setAuctionStatus(0);
      } else setAuctionStatus(2);
    } else {
      setAuctionStatus(2);
    }

    setDueDateTime(deadLine);
  }, []);

  const preventContextMenu = (e) => {
    e.preventDefault(); // ปิดการแสดงเมนูคลิกขวา
  };

  // if (watermarkedImage)
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link
        to={"/products/" + data.id}
        color="inherit"
        component={RouterLink}
        underline="none"
      >
        <Card>
          {/* {watermarkedImage && ( */}
          <Watermark
            // content="ONE LEASING"
            image={WaterMark}
            height={40}
            width={120}
          >
            <CardMedia
              component="img"
              // height="200"
              image={data.image_path}
              alt="Paella dish"
              onContextMenu={preventContextMenu} // ป้องกันการคลิกขวา
            />
          </Watermark>

          <CardContent>
            {checKAuctionStatus === 1 ? (
              <Countdown
                // title="ระยะเวลาปิดประมูล"
                valueStyle={{
                  color: colorCooldown,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                value={dueDateTime}
                format="เหลือ D วัน H:m:s ชั่วโมง"
              />
            ) : checKAuctionStatus === 2 ? (
              <Typography style={{ fontWeight: "bold", color: "red" }}>
                ปิดประมูลแล้ว
              </Typography>
            ) : (
              <Typography style={{ fontWeight: "bold" }}>
                ยังไม่เปิดประมูล
              </Typography>
            )}
            <Divider />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {data.year} {data.brand || data.TYPE}
                </Typography>
                <Typography variant="body2">
                  {data.model || data.MODEL}
                </Typography>
                <Typography
                  style={{ fontSize: 12, color: "gray" }}
                  variant="body2"
                >
                  {currencyFormat(data.miles)} กม. | {data.gear_type}
                </Typography>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  ราคาเปิดประมูล
                </Typography>
                <Typography style={{ fontSize: 14 }} color="green">
                  {checKAuctionStatus === 0
                    ? "ยังไม่เปิดเผย"
                    : currencyFormat(data.auction_price)}
                </Typography>
              </div>
              <div>
                {/* <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton> */}
              </div>
            </div>
            {/* <Divider /> */}
            {/* <div>
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                ยอดประมูลล่าสุด
              </Typography>
              <Typography variant="body1" color="red">
                {data.auction_total
                  ? currencyFormat(data.auction_total) + " บาท"
                  : "ยังไม่มี"}
              </Typography>
              <Typography style={{ color: "gray" }} variant="subtitle2">
                โดยคุณ {data.user_userid ? censorName(data.user_name) : "-"}
              </Typography>
            </div> */}
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}
