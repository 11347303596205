import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Card,
  Modal,
  Checkbox,
} from "antd";
import { Container, useMediaQuery } from "@mui/material";
import Logo from "../../assets/images/ONExKSM.png";
import KSMLogo from "../../assets/images/KSM-Blue.png";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import carApi from "api/carApi";
import OTPpassword from "./OTPPassword";
import axios from "axios";

import { useDispatch } from "react-redux";
import { addToken } from "components/store/tokenSlice";

const { Title } = Typography;

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [otpToken, setOtpToken] = useState({});
  const [serverToken, setServerToken] = useState("");
  const [onFailure, setOnFailure] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    // console.log(values);
    signin(values);
  };

  const signin = async (values) => {
    // console.log(values);
    await carApi
      .post(`/salecar/login`, { data: values })
      .then((res) => {
        setServerToken(res.data);
        requestOTP(res.data.phone);
      })
      .catch((err) => {
        Modal.error({
          title: "ไม่พบผู้ใช้งาน",
          content: "หมายเลขนี้ยังไม่ได้ลงทะเบียน หรืออยู่ระหว่างพิจารณา",
          centered: true,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const newFormatPhone = (value) => {
    // console.log(value);
    return value.match(/\d/g).join("") || value;
  };

  const requestOTP = async (phoneRaw) => {
    const phone = newFormatPhone(phoneRaw);
    var config = {
      method: "post",
      url: "https://portal-otp.smsmkt.com/api/otp-send",
      headers: {
        "Content-Type": "application/json",
        secret_key: "5Oa9NV8XnqTOCk9S",
        api_key: "32d2769eb59b8dd02659a04c34f6c6a0",
      },
      data: JSON.stringify({
        project_key: "4ceaa6a850",
        phone,
      }),
    };
    await axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data))
        setOtpToken(response.data);
        setOpenOTPModal(true);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleRequestOTP = () => {
    Modal.success({ content: "รหัส OTP ได้ถูกส่งไปยังเบอร์ของท่านแล้ว" });
    requestOTP(serverToken.phone);
  };

  const confirmOTP = async (otp) => {
    // console.log('OTP PASS', otp)

    // console.log('TOKEN', otpToken.result.token)
    var config = {
      method: "post",
      url: "https://portal-otp.smsmkt.com/api/otp-validate",
      headers: {
        "Content-Type": "application/json",
        secret_key: "5Oa9NV8XnqTOCk9S",
        api_key: "32d2769eb59b8dd02659a04c34f6c6a0",
      },
      data: JSON.stringify({
        token: otpToken.result.token,
        otp_code: otp,
      }),
    };
    axios(config)
      .then((response) => {
        // console.log(response.data);
        if (response.data.result.status === true) {
          // UpdateUserLogin({ data: user })
          setOpenOTPModal(false);
          dispatch(addToken(serverToken));

          navigate("/");
        } else {
          setOnFailure(true);
        }
      })
      .catch(function (error) {
        Modal.error({ content: "ไม่สามารถเชื่อมต่อ Server OTP ได้" });
        // alert("ไม่สามารถเชื่อมต่อ Server OTP ได้");
      });
  };

  return (
    <>
      <Container className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            style={{
              // padding: 12,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img width={isMediumUp ? 500 : 350} src={Logo} alt="" />
          </Col>
          <Col>
            <Title className="mb-15">เข้าสู่ระบบ</Title>
            <Title className="font-regular text-muted" level={5}>
              เข้าสู่ระบบเพื่อร่วมประมูลรถ
            </Title>
            <Card
              title={<h4>เข้าสู่ระบบด้วยหมายเลขที่ท่านลงทะเบียน</h4>}
              bordered="false"
            >
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  label="หมายเลขโทรศัพท์"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "หมายเลขไม่ถูกต้อง!",
                    },
                  ]}
                >
                  <Input placeholder="หมายเลขโทรศัพท์ 10 หลัก" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>จดจำฉัน</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    เข้าสู่ระบบ
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                  ยังไม่มีบัญชีผู้ใช้งาน?{" "}
                  <Link to="/sign-up" className="text-dark font-bold">
                    สมัครสมาชิก
                  </Link>
                </p>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* <div
          style={{ marginTop: 50, justifyContent: "center", display: "flex" }}
        >
          <Link to="/products">กลับสู่หน้าหลัก</Link>
        </div> */}
        <div
          style={{
            marginTop: 50,
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className="copyright">
            {" "}
            Copyright © 2023 by{" "}
            <a href="https://www.facebook.com/calleasing.kkn/">
              {" "}
              ONE LEASING.CO.,LTD
            </a>
            .{" "}
          </p>
        </div>
      </Container>
      <OTPpassword
        open={openOTPModal}
        close={setOpenOTPModal}
        onFailure={onFailure}
        otpResult={confirmOTP}
        onRequestOTP={handleRequestOTP}
      />
    </>
  );
}
