import React, { useEffect, useState } from "react";
import carApi from "api/carApi";
import {
  Button,
  Image,
  Space,
  Table,
  Tag,
  Typography,
  Statistic,
  Divider,
  Modal,
} from "antd";

import moment from "moment";
import { currencyFormat } from "components/utils/Utils";
import AuctionProductListModal from "./AuctionProductListModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";

const { Countdown } = Statistic;

export default function AuctionCompleteListWithUser() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [auctionCars, setAuctionCars] = useState([]);
  const [openAuctionListModal, setOpenAuctionListModal] = useState(false);
  const [auctionList, setAuctionList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAuctionCars();
  }, []);

  const loadAuctionCars = async () => {
    setLoading(true);
    await carApi
      .get(`/salecar/users/${id}/auctions/`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setAuctionCars(response.data);

        // setImageList([{ url: response.data.image_path }]);
        //   if (response.data.product_code) {
        //     loadImagesProduct(response.data.product_code);
        //     setImageShow(response.data.image_path);
        //   }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // Modal.error({ title: "ผิดพลาด", content: err.message, centered: true });
      });
  };

  const fetchAuctionListByUserId = async () => {
    await carApi
      .get(`/salecar/auction-log/user/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAuctionList(res.data);
        setOpenAuctionListModal(true);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleAuctionListClick = () => {
    fetchAuctionListByUserId();
  };

  const compareAuctionPrice = (data) => {
    if (data.auction_total > data.user_price) {
      return <Tag color="red">แพ้ประมูล</Tag>;
    } else {
      return <Tag color="green">ชนะประมูล</Tag>;
    }
  };

  const columns = [
    {
      key: 1,
      dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return <Image width={200} src={rec} />;
      },
    },
    {
      key: 2,
      title: "รายละเอียดสินค้า",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Link to={"/products/" + rec.id}>
              รหัสสินค้า : {rec.product_code}
            </Link>
            <Typography style={{ color: "gray" }}>
              วันที่ลง {moment(rec.date).format("ll")}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>{rec.brand}</Typography>
            <Typography>{rec.model}</Typography>
            <Typography>{rec.year}</Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ {currencyFormat(rec.miles)} กม.
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ราคาเปิด {currencyFormat(rec.auction_price)}
            </Typography>
          </div>
        );
      },
    },
    {
      key: 2,
      title: "รายการประมูล",
      render: (rec) => {
        let auctionStatus;
        let color = "green";
        const currentDateTime = new Date();
        const deadLine = Date.parse(rec.auction_due_datetime);
        const miliseconds = deadLine - currentDateTime;
        // console.log(rec.id, deadLine);
        if (rec.status_type_id === 1) {
          if (deadLine > currentDateTime) {
            auctionStatus = 1;
            if (miliseconds <= 6000000) {
              color = "red";
            } else color = "green";
          } else if (isNaN(deadLine) || deadLine === null) {
            auctionStatus = 0;
          } else auctionStatus = 2;
        } else {
          auctionStatus = 2;
        }

        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            {/* <Typography style={{ fontWeight: "bold", color: "red" }}>
                  {"ยอดประมูลรถ : " + currencyFormat(rec.auction_total)}
                </Typography>
                <Typography style={{ color: "gray" }}>
                  {"ประมูลล่าสุด : " +
                    moment(rec.auction_total_update_datetime).format("lll")}
                </Typography> */}
            {auctionStatus === 1 ? (
              <>
                <Countdown
                  title="ระยะเวลาปิดประมูล"
                  valueStyle={{
                    color: color,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  value={deadLine}
                  format="D วัน H ชั่วโมง m นาที s วินาที"
                />
                <Typography style={{ fontWeight: "bold" }}>
                  ยอดสูงสุดตอนนี้ : {currencyFormat(rec.auction_total)}
                </Typography>
              </>
            ) : (
              <>
                <Typography style={{ fontWeight: "bold", color: "red" }}>
                  ปิดประมูลแล้ว
                </Typography>
                {/* <Typography style={{ fontWeight: "bold" }}>
                  ยอดปิดประมูล {currencyFormat(rec.auction_total)}
                </Typography> */}
              </>
            )}

            <Space size={12} direction="horizontal">
              <Typography style={{ fontWeight: "bold", color: "gray" }}>
                ยอดประมูลของท่าน {currencyFormat(rec.user_price)}{" "}
              </Typography>
              {/* {compareAuctionPrice(rec)} */}
              {auctionStatus === 1 && (
                <Link to={"/products/" + rec.id}>เพิ่มราคา</Link>
              )}
            </Space>
          </div>
        );
      },
    },
    {
      key: 4,
      //   dataIndex: "status_type_id",
      title: "สถานะสินค้า",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";

            break;
          case 2:
            color = "processing";

            break;
          case 3:
            color = "error";

            break;

          default:
            break;
        }

        return (
          <Space direction="vertical">
            <Tag color={color}>{rec.status}</Tag>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleAuctionListClick} type="primary">
          รายการที่ประมูลทั้งหมด
        </Button>
      </div>
      <Table dataSource={auctionCars} columns={columns}></Table>
      <AuctionProductListModal
        open={openAuctionListModal}
        data={auctionList}
        onClose={setOpenAuctionListModal}
      />
    </div>
  );
}
