import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductsRoute from "components/products/Routers";
import UsersRoute from "components/users/Routers";
import StockRoute from "components/stock/Routers";
import RetailRoute from "components/retails/Routers";
import ReportRoute from "components/report/Routers";
import { useSelector } from "react-redux";

export default function Routers() {
  const user = useSelector((state) => state.token);
  // console.log("ROUTER", user);
  if (user?.role === "admin") {
    return (
      <Routes>
        <Route path="products/*" element={<ProductsRoute />} />
        <Route path="retails/*" element={<RetailRoute />} />
        <Route path="stocks/*" element={<StockRoute />} />
        <Route path="reports/*" element={<ReportRoute />} />
        <Route path="users/*" element={<UsersRoute />} />
      </Routes>
    );
  } else if (user?.role === "finance") {
    return (
      <Routes>
        <Route path="products/*" element={<ProductsRoute />} />
      </Routes>
    );
  } else if (user?.role === "sub admin") {
    return (
      <Routes>
        <Route path="products/*" element={<ProductsRoute />} />
        <Route path="stocks/*" element={<StockRoute />} />
      </Routes>
    );
  } else if (user?.role === "user") {
    return (
      <Routes>
        <Route path="products/*" element={<ProductsRoute />} />
        <Route path="retails/*" element={<RetailRoute />} />
        {/* <Route path="reports/*" element={<ReportRoute />} /> */}
        <Route path="users/*" element={<UsersRoute />} />
      </Routes>
    );
  } else if (user?.role === "seller") {
    return (
      <Routes>
        {/* <Route path="products/*" element={<ProductsRoute />} /> */}
        <Route path="retails/*" element={<RetailRoute />} />
        <Route path="users/*" element={<UsersRoute />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="products/*" element={<ProductsRoute />} />
      {/* <Route path="users/*" element={<UsersRoute />} /> */}
    </Routes>
  );
}
