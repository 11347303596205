import { Divider, Modal, Space, Table, Typography } from "antd";
import { currencyFormat } from "components/utils/Utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";

const { Text } = Typography;

export default function PreviewCarDetailModal({
  open,
  data,
  onClose,
  onSubmit,
}) {
  useEffect(() => {
    console.log("DATA", data);
    // setList(data);
  }, [data]);

  const handleClose = () => {
    onSubmit({ ...data, approved_status_id: 2 });
    onClose(false);
  };

  const handleSubmit = () => {
    onSubmit({ ...data, approved_status_id: 1 });
    onClose(false);
  };

  const CustomTextField = ({ text }) => {
    return (
      <div
        style={{
          border: "1px solid rgba(128,128,128,0.3)",
          borderRadius: "6px",
          padding: 4,
        }}
      >
        <Typography style={{ marginLeft: 4, color: "gray" }}>{text}</Typography>
      </div>
    );
  };

  return (
    <Modal
      //   width={800}
      closable={false}
      title="รายละเอียด"
      open={open}
      onOk={handleSubmit}
      onCancel={handleClose}
      okText="อนุมัติ"
      cancelText="ไม่อนุมัติ"
    >
      <Space direction="vertical">
        <Text>
          ผู้ซื้อ : {data.buyer_firstname} {data.buyer_lastname}
        </Text>
        <Text>ที่อยู่ : {data.buyer_address} </Text>
        <Text>โทรศัพท์ : {data.buyer_phone} </Text>
        <Text>ชื่อเต๊นท์รถ : {data.buyer_car_tent_name} </Text>
        <Text>ที่อยู่เต๊นท์ : {data.buyer_car_tent_address} </Text>
        <Divider />
        <Text>
          ยี่ห้อรถ : ({data.year}) {data.brand || data.TYPE}{" "}
          {data.model || data.MODEL}{" "}
        </Text>
        <Text>ทะเบียน : {data.register_no} </Text>
        <Text>เลขคัตซี : {data.chassis_no} </Text>
        <Text>สี : {data.color} </Text>
        <Text>เกียร์ : {data.gear_type} </Text>
        <Text strong>ราคาขาย : {currencyFormat(data.sold_out_price)} </Text>
      </Space>
    </Modal>
  );
}
