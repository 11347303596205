import { currencyFormat } from "components/utils/Utils";
import moment from "moment";
import "moment/locale/th";
import KSM_LOGO from "../../../assets/images/KSM-logo.png";
import KSM_Watermark from "../../../assets/images/KSMWatermark.png";
import ONE_LOGO from "../../../assets/images/One Leasing Logo.png";
import ONE_Watermark from "../../../assets/images/WaterMark.png";
import ONE_MANAGER_SIGNATURE from "../../../assets/images/JuaSignature.png";
import KSM_MANAGER_SIGNATURE from "../../../assets/images/JumSignature.png";

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

export const offerPricePDF = async (data) => {
  let companyName = "";
  let companyAddress = "";
  let signature = "";
  if (data.company === 2) {
    signature = KSM_MANAGER_SIGNATURE;
    companyName = `บริษัท เค.เอส.เอ็ม. บิลเลี่ยนแนร์ จำกัด\r\nK.S.M BILLIENAIR CO.,LTD.`;
    companyAddress = `279 หมู่ 12 ถนนมิตรภาพ ตำบลเมืองเก่า อำเภอเมืองขอนแก่น จังหวัดขอนแก่น 40000\r\nโทรศัพท์ 043-306822 เลขที่ผู้เสียภาษี 0-4055-47000-97-0 (สำนักงานใหญ่)`;
  } else {
    signature = ONE_MANAGER_SIGNATURE;
    companyName = `บริษัท วัน ลิสซิ่ง จำกัด\r\nONE LEASING CO.,LTD.`;
    companyAddress = `1/20-24 ถนนมิตรภาพ ตำบลในเมือง อำเภอเมืองขอนแก่น จังหวัดขอนแก่น 40000\r\nโทรศัพท์ 043-239888 เลขที่ผู้เสียภาษี 0-4055-59001-92-5 (สำนักงานใหญ่)`;
  }
  var docDefinition = {
    pageSize: "A4",
    // [left, top, right, bottom]
    pageMargins: [30, 30, 30, 30],
    background: [
      {
        image:
          data.company === 2
            ? await getBase64ImageFromURL(KSM_Watermark)
            : await getBase64ImageFromURL(ONE_Watermark),
        width: 500,
        alignment: "center",
        margin: [0, 150, 0, 0],
      },
    ],
    content: [
      {
        columns: [
          {
            image:
              data.company === 2
                ? await getBase64ImageFromURL(KSM_LOGO)
                : await getBase64ImageFromURL(ONE_LOGO),

            width: data.company === 2 ? 150 : 200,
            height: 60,
          },
          {
            stack: [
              {
                text: companyName,
                style: "textBody",
                alignment: "center",
                fontSize: 20,
                bold: true,
              },
              {
                text: companyAddress,
                style: "textBody",
                alignment: "center",
                fontSize: 10,
                bold: true,
                // margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0, // จุดเริ่มต้นของเส้น (แกน X ซ้ายสุด)
            y1: 0, // จุดเริ่มต้นของเส้น (แกน Y)
            x2: 535, // จุดสิ้นสุดของเส้น (แกน X ขวาสุด)
            y2: 0, // จุดสิ้นสุดของเส้น (แกน Y เดิม = แนวนอน)
            lineWidth: 0.5, // ความหนาของเส้น
            lineColor: "black", // สีของเส้น
            // dash: { length: 5, space: 3 }, // ความยาวเส้นและช่องว่างระหว่างประ
          },
        ],
        margin: [0, 20, 0, 20],
      },
      {
        text: `ใบคำขอซื้อรถยนต์ (ใบจองรถ)`,
        // style: "textBody",
        alignment: "center",
        fontSize: 18,
        bold: true,
      },
      // {

      // stack: [

      {
        alignment: "right",
        text: [
          {
            text: `วันที่พิมพ์   `,
            style: "textBody",
            bold: true,
            fontSize: 14,
          },
          {
            text: ` ${moment(new Date()).format("LL")}`,
            style: "textBody",
            // alignment: "right",
            fontSize: 14,
          },
        ],
      },

      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                margin: [10, 10, 10, 10],
                stack: [
                  {
                    text: [
                      {
                        text: `ชื่อผู้ซื้อ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${
                          data.buyer_nickname
                            ? data.buyer_firstname +
                              " " +
                              data.buyer_lastname +
                              " (" +
                              data.buyer_nickname +
                              ")"
                            : data.buyer_firstname + " " + data.buyer_lastname
                        }`,
                        style: "textBody",
                        // alignment: "right",
                        fontSize: 14,
                      },
                      {
                        text: `   ที่อยู่ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${data.buyer_address}  ${
                          data.buyer_zipcode || ""
                        } `,
                        style: "textBody",
                        // alignment: "right",
                        fontSize: 14,
                      },
                      {
                        text: `   โทรศัพท์ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${data.buyer_phone}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        text: `บัตรประชาชน, ใบขับขี่ เลขที่ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${data.buyer_idcard || ""}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        text: `ชื่อเต็นท์รถ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${data.buyer_car_tent_name || "-"}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                      {
                        text: `   ที่อยู่ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${data.buyer_car_tent_address || "-"}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                      {
                        text: `   โทรศัพท์ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${data.buyer_car_tent_phone || "-"}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },

      // ],
      // },
      {
        margin: [0, 10, 0, 10],
        table: {
          headerRows: 1,
          heights: [20, 220, 70],
          widths: [100, 280, "*"],
          body: [
            [
              {
                text: "รหัสสินค้า",
                alignment: "center",
                bold: true,
                fontSize: 14,
              },
              {
                text: "รายละเอียด",
                alignment: "center",
                bold: true,
                fontSize: 14,
              },

              {
                text: "ราคาขาย",
                alignment: "center",
                bold: true,
                fontSize: 14,
              },
            ],
            [
              {
                margin: [0, 10, 0, 0],
                stack: [
                  {
                    text: ` ${data.product_code}`,
                    fontSize: 14,
                  },
                  {
                    text: ` ${data.contract_no}`,
                    fontSize: 14,
                  },
                ],
              },
              {
                margin: [0, 10, 0, 0],
                stack: [
                  {
                    fontSize: 14,
                    text: `${data.brand}  ${data.model}  (${data.year})  สี ${data.color} `,
                  },

                  {
                    text: `${data.gear_type}  เลขไมล์ ${data.miles}`,
                    fontSize: 14,
                  },
                  {
                    text: `เลขทะเบียน ${data.register_no}`,
                    fontSize: 14,
                  },
                  {
                    text: `เลขคัตซี ${data.chassis_no}`,
                    fontSize: 14,
                  },
                ],
              },
              {
                margin: [0, 10, 0, 0],
                stack: [
                  {
                    // margin: [0, 10, 0, 0],
                    alignment: "right",
                    text: ` ${currencyFormat(data.sold_out_price)}`,
                    fontSize: 14,
                  },
                  {
                    columns: [
                      { text: "เงินมัดจำ", fontSize: 14, alignment: "left" },
                      {
                        text: `${currencyFormat(data.reserve_price)}`,
                        fontSize: 14,
                        alignment: "right",
                      },
                    ],
                    widths: ["auto", "auto"], // ทำให้ตรงกลางยืดออก
                  },
                  {
                    columns: [
                      { text: "จ่ายวันรับรถ", fontSize: 14, alignment: "left" },
                      {
                        text: `${currencyFormat(
                          data.sold_out_price - data.reserve_price
                        )}`,
                        fontSize: 14,
                        alignment: "right",
                      },
                    ],
                    widths: ["auto", "auto"], // ทำให้ตรงกลางยืดออก
                  },
                ],
              },
            ],
            [
              {
                border: [true, false, false, true],
                margin: [0, 0, 0, 20],
                stack: [
                  {
                    text: `รายการชำระ`,
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 10],
                  },
                  {
                    text: `หมายเหตุ`,
                    fontSize: 14,
                    bold: true,
                  },
                ],
              },
              {
                border: [false, false, false, true],
                text: `รวมยอดชำระ`,
                fontSize: 14,
                alignment: "right",
                bold: true,
              },
              {
                // border: [true, false, false, true],
                text: `${currencyFormat(data.reserve_price)}`,
                fontSize: 14,
                alignment: "right",
                bold: true,
              },
            ],
          ],
        },
      },
      {
        margin: [0, 70, 0, 0],
        columns: [
          {
            alignment: "center",
            stack: [
              {
                margin: [0, 0, 0, 10],
                text: `\t\t\t\t\t\t\t\t\t\t\t`,
                decoration: "underline",
                decorationStyle: "dotted",
                // fontSize: 14,
              },
              // {
              //   text: `${rec.user_name}  ${rec.user_lastname}`,
              //   fontSize: 14,
              // },
              {
                text: `ผู้ซื้อ`,
                fontSize: 14,

                bold: true,
              },
              {
                margin: [0, 10, 0, 0],
                text: `วันที่............................................`,
                fontSize: 14,
              },
            ],
          },
          {
            alignment: "center",
            stack: [
              {
                margin: [0, 0, 0, 10],
                text: `\t\t\t\t\t\t\t\t\t\t\t`,
                decoration: "underline",
                decorationStyle: "dotted",
                // fontSize: 14,
              },
              // {
              //   text: `ศุภมิตร แก้วสุโพธิ์`,
              //   fontSize: 14,
              // },
              {
                text: `ฝ่ายขาย`,
                fontSize: 14,
                bold: true,
              },
              {
                margin: [0, 10, 0, 0],
                text: `วันที่............................................`,
                fontSize: 14,
              },
            ],
          },
          {
            alignment: "center",
            stack: [
              {
                margin: [0, 0, 0, 10],
                text: `\t\t\t\t\t\t\t\t\t\t\t`,
                decoration: "underline",
                decorationStyle: "dotted",
                // fontSize: 14,
              },
              // {
              //   text: `รัมภา อรุณเดชาชัย`,
              //   fontSize: 14,
              // },
              {
                text: `ผู้อนุมัติขาย`,
                fontSize: 14,
                bold: true,
              },
              {
                margin: [0, 10, 0, 0],
                text: [
                  {
                    text: `วันที่`,
                  },
                  {
                    text: `\t\t${moment()
                      .add(543, "years")
                      .format("DD/MM/YY")}\t\t`,
                    decoration: "underline",
                    decorationStyle: "dotted",
                  },
                ],
                fontSize: 14,
              },
            ],
          },
        ],
      },
      {
        image: await getBase64ImageFromURL(signature),
        width: data.company === 1 ? 150 : 100,
        absolutePosition:
          data.company === 1 ? { x: 400, y: 620 } : { x: 420, y: 600 },
      },
    ],
    defaultStyle: { font: "THSarabunNew" },
    styles: {
      withMargin: {
        margin: [20, 20, 20, 20],
      },
      alignCenter: {
        alignment: "center",
      },
      header: {
        fontSize: 18,
        bold: true,
      },
      textBody: {
        fontSize: 16,
      },
      subheader: {
        fontSize: 15,
        bold: true,
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
    },
  };
  return docDefinition;
};
