import React, { useState, useEffect } from "react";

import carApi from "api/carApi";
import {
  Button,
  Image,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Statistic,
  Radio,
  Modal,
  DatePicker,
  Divider,
  Select,
  Input,
} from "antd";
import { ClockCircleOutlined, FormOutlined } from "@ant-design/icons";
import { currencyFormat } from "components/utils/Utils";
import Loading from "components/Loading";
import moment from "moment";
import PreviewProductModal from "./PreviewProductModal";
import { Link, useNavigate } from "react-router-dom";
import AuctionUserListModal from "../auction/AuctionUserListModal";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import EditProductDetailModal from "./EditProductDetailModal";
import AuctionUserListReportExcel from "./AuctionUserListReportExcel";
// import AuctionByUserReportExcel from "./AuctionByUserReportExcel";
import SelectUserListReportModal from "./SelectUserListReportModal";
import SelectCompanyModal from "../auction/SelectCompanyModal";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { offerPricePDF } from "../auction/OfferPricePDF";
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew Bold.ttf",
    italics: "THSarabunNew Italic.ttf",
    bolditalics: "THSarabunNew BoldItalic.ttf",
  },
  AngsanaNew: {
    normal: "AngsanaNew.ttf",
    bold: "AngsanaNew Bold.ttf",
    italics: "AngsanaNew Italic.ttf",
    bolditalics: "AngsanaNew Bold Italic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Countdown } = Statistic;

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });

export default function TableProductList() {
  const user = useSelector((state) => state.token);
  // console.log("USER", user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataHolder, setDataHolder] = useState([]);
  const [productData, setProductData] = useState({});
  const [auctionUserList, setAuctionUserList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAuctionListModal, setOpenAuctionListModal] = useState(false);
  const [openEditDataModal, setOpenEditDataModal] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [successUploaded, setSuccessUploaded] = useState(false);
  const [searchType, setSearchType] = useState(1);
  const [openSelectCompanyModal, setOpenSelectCompanyModal] = useState(false);
  const [openSelectUserModal, setOpenSelectUserModal] = useState(false);
  // const [imageList, setImageList] = useState([]);
  // const [coverImage, setCoverImage] = useState([]);
  //   const [dueDateTime, setDueDateTime] = useState("");
  //   const [checKAuctionStatus, setAuctionStatus] = useState(false);
  //   const [colorCooldown, setColorCooldown] = useState("green");

  const columns = [
    {
      key: 1,
      // dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Image width={200} src={rec.image_path} />
            <Typography>สัญญาเดิม : {rec.contract_no}</Typography>
            <Typography>ทะเบียน : {rec.register_no}</Typography>
            <Typography.Text type="secondary">
              เข้าระบบ {moment(rec.date).startOf("day").fromNow()}
            </Typography.Text>
          </div>
        );
      },
    },

    {
      key: 2,
      title: "รายละเอียดสินค้า",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";
            break;
          case 2:
            color = "processing";
            break;
          case 3:
            color = "error";
            break;
          case 4:
            color = "error";
            break;
          default:
            break;
        }
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Space>
              <p>สถานะ</p>
              <Tag color={color}>{rec.status}</Tag>
            </Space>
            <Link to={"/products/" + rec.id}>
              รหัสสินค้า : {rec.product_code}
            </Link>
            <Typography style={{ color: "gray" }}>
              วันที่ลง {moment(rec.date).format("ll")}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ({rec.year}) {rec.brand || rec.TYPE}
            </Typography>
            <Typography style={{ color: "gray" }}>
              {rec.model || rec.MODEL}
            </Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ {currencyFormat(rec.miles)} กม.
            </Typography>
            <Typography style={{ color: "gray" }}>{rec.chassis_no}</Typography>
            {(user.id === 7 ||
              user.id === 9 ||
              user.id === 10 ||
              user.id === 63 ||
              user.id === 80) && (
              <Typography style={{ fontWeight: "bold", color: "green" }}>
                ราคาต้นทุน {currencyFormat(rec.cost_price)}
              </Typography>
            )}
            <Typography style={{ fontWeight: "bold" }}>
              ราคาเปิด {currencyFormat(rec.auction_price)}
            </Typography>
          </div>
        );
      },
    },
    user?.role !== "sub admin"
      ? {
          key: 3,
          title: "รายละเอียดการประมูล",
          render: (rec) => {
            let auctionStatus;
            let color = "green";
            const currentDateTime = new Date();
            const deadLine = Date.parse(rec.auction_due_datetime);
            const miliseconds = deadLine - currentDateTime;
            // console.log(rec.id, deadLine);
            if (rec.status_type_id === 1) {
              if (deadLine > currentDateTime) {
                auctionStatus = 1;
                if (miliseconds <= 6000000) {
                  color = "red";
                } else color = "green";
              } else if (isNaN(deadLine) || deadLine === null) {
                auctionStatus = 0;
              } else auctionStatus = 2;
            } else {
              auctionStatus = 2;
            }

            // setDueDateTime(deadLine);
            return (
              <div style={{ flexDirection: "column", display: "flex" }}>
                {auctionStatus === 1 ? (
                  <>
                    <Countdown
                      title="ระยะเวลาปิดประมูล"
                      valueStyle={{
                        color: color,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                      value={deadLine}
                      format="D วัน H ชั่วโมง m นาที s วินาที"
                    />
                    <Typography style={{ fontWeight: "bold" }}>
                      ยอดสูงสุดตอนนี้ : {currencyFormat(rec.auction_total)}
                    </Typography>
                  </>
                ) : auctionStatus === 2 ? (
                  <>
                    <Typography style={{ fontWeight: "bold", color: "red" }}>
                      ปิดประมูลแล้ว
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      ยอดปิดประมูล {currencyFormat(rec.auction_total)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography style={{ fontWeight: "bold" }}>
                      ยังไม่เริ่มประมูล
                    </Typography>
                    {/* <Button type="primary">เปิดประมูล</Button> */}
                  </>
                )}

                {rec.process_by_user_id ? (
                  <>
                    <Link to={"/users/" + rec.user_id}>
                      จากผู้ใช้ :{" "}
                      {rec.user_car_tent_name ||
                        rec.user_name + " " + rec.user_lastname}
                    </Link>
                    <Link
                      style={{ marginTop: 16 }}
                      onClick={() => fetchAuctionUserList(rec.id)}
                    >
                      ดูผู้ร่วมประมูลทั้งหมด
                    </Link>
                  </>
                ) : null}
              </div>
            );
          },
        }
      : {},
    user?.role !== "sub admin"
      ? {
          key: 4,
          //   dataIndex: "status_type_id",
          title: "ตัวเลือก",
          render: (rec) => {
            return (
              <Space align="center">
                <Tooltip title="จัดการประมูล">
                  <Button
                    size="large"
                    shape="circle"
                    icon={<ClockCircleOutlined />}
                    type="primary"
                    onClick={() => {
                      handleClickAuction(rec);
                    }}
                  />
                </Tooltip>
                <Tooltip title="แก้ไขข้อมูลสินค้า">
                  <Button
                    size="large"
                    shape="circle"
                    icon={<FormOutlined />}
                    onClick={() => {
                      handleClickEdit(rec);
                    }}
                  />
                </Tooltip>
                {/* <Tooltip title="พิมพ์ใบขอซื้อ">
              <Button
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={() => handleClickPrintOfferPrice(rec)}
              />
            </Tooltip> */}
              </Space>
            );
          },
        }
      : {},
  ];

  const handleClickPrintOfferPrice = async (rec) => {
    setDataEdit(rec);
    setOpenSelectCompanyModal(true);
  };

  const handleClickEdit = (data) => {
    console.log(data);
    setDataEdit(data);
    setOpenEditDataModal(true);
  };

  // const loadImagesProduct = async (product_code) => {
  //   console.log("LoadImageProductCode", product_code);
  //   setLoading(true);
  //   await carApi
  //     .get("/salecar_files/list/" + product_code)
  //     .then((response) => {
  //       console.log(response.data);
  //       // setImageList(response.data);
  //       setLoading(false);
  //       setOpenModal(true);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  const fetchAuctionUserList = async (id) => {
    console.log(id);
    await carApi
      .get(`/salecar/admin/auction-log/product/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setAuctionUserList(response.data);
        setOpenAuctionListModal(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClickAuction = async (rec) => {
    // console.log(rec);
    setLoading(true);
    await carApi
      .get(`/salecar/products/${rec.id}`)
      .then((response) => {
        // console.log(response.data);
        setProductData(response.data);
        setOpenModal(true);
        // setImageList([{ url: response.data.image_path }]);
        // if (response.data.product_code) {
        //   loadImagesProduct(response.data.product_code);
        //   //   setImageShow(response.data.image_path);
        // } else {
        //   setOpenModal(true);
        // }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const fetchAllProduct = async () => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/products", {
        params: { type: 1 },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setDataHolder(data);
        setLoading(false);
        // setImageList([{ url: response.data.image_path }]);
        // if (response.data.product_code) {
        //   loadImagesProduct(response.data.product_code);
        //   //   setImageShow(response.data.image_path);
        // } else {
        //   setOpenModal(true);
        // }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
    // console.log("Products", data);
  };

  useEffect(() => {
    if (successUploaded) {
      fetchAllProduct();
      setSuccessUploaded(false);
    }
  }, [successUploaded]);

  const handleSearchTypeStatus = (e) => {
    const term = e.target.value;
    if (term === 0) {
      setData(dataHolder);
    } else {
      const newData = dataHolder.filter((item) => {
        return item.status_type_id === term;
      });
      setData(newData);
    }
  };

  const updateAuctionData = async (data) => {
    // console.log(data);
    setLoading(true);
    await carApi
      .put(`/salecar/admin/auction-status/products/${data.id}`, data, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // setLoading(false);
        // onClose(false);
        Modal.success({ content: "อัพเดตรายการประมูลสำเร็จ", centered: true });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const updateProductDetail = async (product, imagList) => {
    console.log("Submit Product: ", product);
    setLoading(true);
    await carApi
      .put(`/salecar/admin/products/${product.id}`, product, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const updateData = dataHolder.map((item) => {
          if (item.id === product.id) {
            return { ...data };
          } else {
            return { ...item };
          }
        });

        setData(updateData);

        if (imagList.length > 0) {
          // setSuccessUploaded(true);
          // fetchAllProduct();

          if (imagList.length > 0) {
            handleUploadAllImage(product.product_code, imagList);
          } else {
            Modal.success({
              content: "อัพเดตข้อมูลประมูลสำเร็จ",
              centered: true,
            });
          }
        } else {
          setLoading(false);
          Modal.success({
            content: "อัพเดตข้อมูลประมูลสำเร็จ",
            centered: true,
          });
        }

        // fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  // useEffect(() => {
  //   if (productData.image_path !== "" && uploadImageCover) {
  //     // console.log("Before Insert", productData);
  //     updateProductDetail();

  //     // setUploadImageCover(false);
  //   }
  // }, [productData.image_path, uploadImageCover]);

  const handleUploadCover = (product, coverImage, imageList) => {
    // console.log(coverFile);
    const formData = new FormData();
    formData.append("file", coverImage);
    setLoading(true);
    carApi
      .post(
        "/salecar_files/upload/" + product.product_code + "/cover",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // setUploadImageCover(true);
        updateProductDetail({ ...product, image_path: res.data }, imageList);
        setProductData({ ...product, image_path: res.data });
        Modal.success({ content: "อัพโหลดรูปสำเร็จ", centered: true });
      })
      .catch((err) => {
        Modal.error({
          title: "ผิดพลาด",
          content: err.message,
          centered: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploadAllImage = (product_code, fileList) => {
    // console.log("ALL IMAGE", fileList);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    setLoading(true);
    // You can use any AJAX library you like
    carApi
      .post("/salecar_files/upload/" + product_code, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        Modal.success({ content: "อัพเดตข้อมูลประมูลสำเร็จ", centered: true });
      })
      .catch((err) => {
        Modal.error({
          title: "ผิดพลาด",
          content: err.message,
          centered: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteProduct = async (data) => {
    setLoading(true);
    await carApi
      .delete(`/salecar/admin/products/${data.id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        deleteAllImage(data.product_code);
        Modal.success({
          content: "ลบรายการสินค้าสำเร็จ",
          centered: true,
        });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const deleteAllImage = async (product_code) => {
    await carApi
      .post(`/salecar_files/delete/${product_code}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        console.log("ลบรูปทั้งหมดแล้ว");
        // Modal.success({
        //   content: "ลบรายการสินค้าสำเร็จ",
        //   centered: true,
        // });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        // dispatch(addToken({}));
        // navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "ไม่พบโฟลเดอร์รูปภาพ",
          centered: true,
        });
      });
  };

  const fetchProductListByDate = async (date) => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/products/date/" + date, {
        params: { type: 1 },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setDataHolder(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleSubmitEdit = (productData, coverImage, imgList) => {
    if (coverImage) {
      console.log("Cover Image", coverImage);
      handleUploadCover(productData, coverImage, imgList);
    } else {
      console.log("Image List", imgList);
      updateProductDetail(productData, imgList);
    }

    // setProductData(productData);
  };

  const handleDeleteData = (value) => {
    deleteProduct(value);
  };

  const handleDeleteAllImage = (value) => {
    // console.log("DELETE", value);
    updateProductDetail(value, []);
    deleteAllImage(value.product_code);
  };

  const onChangeMonth = (date, dateString) => {
    if (dateString !== "") {
      fetchProductListByDate(dateString);
    }
  };

  const handleChangeSearchType = (data) => {
    // console.log("TYPE", data);
    setSearchType(data);
  };

  const handleSearch = (value) => {
    // setSearchText(value); // อัปเดตข้อความค้นหา
    if (value) {
      let filtered = [];
      if (searchType === 1) {
        filtered = dataHolder.filter(
          (item) => item.contract_no.toLowerCase().includes(value.toLowerCase()) // ค้นหาแบบ case-insensitive
        );
      } else {
        filtered = data.filter((item) =>
          item.register_no.toLowerCase().includes(value.toLowerCase())
        );
      }

      setData(filtered); // อัปเดตข้อมูลที่กรอง
    } else {
      setData(dataHolder);
    }
  };

  const handleClickUserList = () => {
    setOpenSelectUserModal(true);
  };

  const handleSubmitSelectCompany = async (data) => {
    // console.log("DATA SUBMIT", data);
    const docDefinition = await offerPricePDF(data);
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <>
      {/* {loading ? <Loading /> : null} */}
      <h3>รายการสินค้าประมูล</h3>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Space>
          <p>ค้นหาจาก</p>
          <Select
            style={{
              width: 150,
            }}
            options={[
              { value: 1, label: <span>เลขที่สัญญา</span> },
              { value: 2, label: <span>เลขทะเบียน</span> },
            ]}
            onChange={handleChangeSearchType}
          />
          <Input
            placeholder="กรอกเลขทะเบียนหรือเลขคัตซี"
            allowClear
            onChange={(e) => handleSearch(e.target.value)} // เรียกฟังก์ชันค้นหา
            style={{
              width: 200,
            }}
          />
        </Space>
        <Space>
          <p>กรองตามเดือน</p>
          <DatePicker
            onChange={onChangeMonth}
            placeholder="เดือนที่ลงประกาศ"
            picker="month"
            style={{ width: 200 }}
          />
        </Space>
      </div>
      <div
        style={{
          padding: 8,
          justifyContent: "right",
          display: "flex",
        }}
      >
        <Radio.Group
          defaultValue={0}
          buttonStyle="solid"
          onChange={handleSearchTypeStatus}
        >
          <Radio.Button value={0}>ทั้งหมด</Radio.Button>
          <Radio.Button value={1}>คงอยู่</Radio.Button>
          <Radio.Button value={2}>รอดำเนินการ</Radio.Button>
          <Radio.Button value={3}>ขายแล้ว</Radio.Button>
        </Radio.Group>
      </div>
      <Divider />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 24,
        }}
      >
        <Space>
          <AuctionUserListReportExcel />
          {/* <AuctionByUserReportExcel /> */}
          <Button onClick={handleClickUserList}>ยอดรายบุคคล</Button>
        </Space>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <Table dataSource={data} columns={columns} rowKey="id" />
      )}

      <PreviewProductModal
        open={openModal}
        data={productData}
        onSubmit={updateAuctionData}
        onClose={setOpenModal}
      />
      <AuctionUserListModal
        open={openAuctionListModal}
        data={auctionUserList}
        // onSubmit={updateAuctionData}
        onClose={setOpenAuctionListModal}
      />
      <EditProductDetailModal
        open={openEditDataModal}
        data={dataEdit}
        onSubmit={handleSubmitEdit}
        onDelete={handleDeleteData}
        onDeleteAllImage={handleDeleteAllImage}
        onClose={setOpenEditDataModal}
      />

      <SelectUserListReportModal
        open={openSelectUserModal}
        onClose={setOpenSelectUserModal}
      />
      <SelectCompanyModal
        open={openSelectCompanyModal}
        data={dataEdit}
        onClose={setOpenSelectCompanyModal}
        onSubmit={handleSubmitSelectCompany}
      />
    </>
  );
}
